// export const homeLink = 'http://localhost:8080'
// export const fileServer = 'http://localhost/'

// export const apiDomain = 'http://165.22.240.50:8080/'
// export const LoginURL = apiDomain + 'oauth/token'
// export const homeLink = 'http://165.22.240.50'
// export const fileServer = 'http://165.22.56.35:8080/'

// export const apiDomain = 'https://momensdev.sembcorp.com:8443/'
// // Settings for DO Staging
// // export const apiDomain = 'http://localhost/'
// // Settings for local development
// // export const apiDomain = 'http://127.0.0.1:8000/'
// export const LoginURL = apiDomain + 'oauth/token'
// // Settings for DO Staging
// // export const homeLink = 'http://localhost:8080'
// export const homeLink = 'https://momensdev.sembcorp.com/'
// // Settings for DO Staging
// // export const fileServer = 'http://localhost/'
// export const fileServer = 'https://momensdev.sembcorp.com:8443/'

// --------------------------------------------------

// LOCAL DEVELOPMENT

// export const apiDomain = 'http://127.0.0.1:8000/'
// export const LoginURL = apiDomain + 'oauth/token'
// export const homeLink = 'https://momensdev.sembcorp.com/'
// export const fileServer = 'https://momensdev.sembcorp.com:8443/'


// LOCAL DEVELOPMENT XAMPP

// export const apiDomain = 'http://momens-local.test/'
// export const LoginURL = apiDomain + 'oauth/token'
// export const homeLink = 'https://momensdev.sembcorp.com/'
// export const fileServer = 'http://momens-local.test/'


// MOMENS DO STAGING

// export const apiDomain = 'http://139.59.236.39:8080/'
// export const LoginURL = apiDomain + 'oauth/token'
// export const homeLink = 'http://139.59.236.39:8080/'
// export const fileServer = 'http://localhost/'


// ITRACK DO STAGING

// export const apiDomain = 'https://itrack.imttech.co/api/'
// export const LoginURL = apiDomain + 'oauth/token'
// export const homeLink = 'https://itrack.imttech.co/api/'
// export const fileServer = 'http://localhost/'


// ITRACK DO DEV STAGING

// export const apiDomain = 'https://itrack-dev.imttech.co/api/'
// export const LoginURL = apiDomain + 'oauth/token'
// export const homeLink = 'https://itrack-dev.imttech.co/api/'
// export const fileServer = 'http://localhost/'

// ITRACK SANDBOX

export const apiDomain = 'https://itrack-sandbox.imttech.co/api/'
export const LoginURL = apiDomain + 'oauth/token'
export const homeLink = 'https://itrack-sandbox.imttech.co/api/'
export const fileServer = 'https://itrack-sandbox.imttech.co/api/'

// MOMENSDEV

// export const apiDomain = 'https://momensdev.sembcorp.com:8443/'
// export const LoginURL = apiDomain + 'oauth/token'
// export const homeLink = 'https://momensdev.sembcorp.com/'
// export const fileServer = 'https://momensdev.sembcorp.com:8443/'


// MOMENS PRODUCTION

// export const apiDomain = 'https://momensfile.sembcorp.com/'
// export const LoginURL = apiDomain + 'oauth/token'
// export const homeLink = 'https://momens.sembcorp.com/'
// export const fileServer = 'https://momensfile.sembcorp.com/'


// --------------------------------------------------

export const clientId = '93653827-41fa-4dc4-bc42-7349280e37a7'
export const clientSecret = 'cyLPlmkgjRkjiVjz1z1OITA47id883CHl6QYqZX2'

export const getHeader = function () {
    const tokenData = JSON.parse(window.localStorage.getItem('authUser'))
    const headers = {
        'Accept' : 'application/json',
        'Authorization' : 'Bearer ' + tokenData.access_token
    }

    return headers
}

export const getcurrentDate = function ()
{
  const current       = new Date()
  const currentMonth  = String((current.getMonth()+1)).padStart(2, '0')
  const currentDay    = String(current.getDate()).padStart(2, '0')
  const currentDate   = current.getFullYear()+'-'+currentMonth+'-'+currentDay

  return currentDate
}

export const getUserInfo = function () {
    const loginUserData = JSON.parse(window.localStorage.getItem('loginUser'))
    return loginUserData
}
