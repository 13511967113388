<style>
.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active {
    background: #033639;
    background-color: #033639;
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: white;
}
.dropdown-menu {
  background-color: #033639;
}
.dropdown-item:hover {
 background-color: #27c298;
}
</style>
<template>
<div>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{'bg-success navbar-dark': type === 'default'}"
  >
    <!-- Search form -->
    <b-form class="navbar-search form-inline mr-sm-3"
          :class="{'navbar-search-light': type === 'default', 'navbar-search-dark': type === 'light'}"
          id="navbar-search-main">
      <b-form-group class="mb-0">
        <!-- <b-input-group class="input-group-alternative input-group-merge">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <b-form-input placeholder="Search" type="text"> </b-form-input>
        </b-input-group> -->
          <!-- <base-input label="Basic select">
                    <select class="form-control" id="exampleFormControlSelect1">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                  </base-input>
           <base-button size="sm" type="neutral" @click="notready()">Change</base-button>
           <base-button size="sm" type="neutral" @click="notready()">Set As Default</base-button> -->
           <!-- <b @click="modals.formDashboard = true"><i size="sm" class="ni ni-settings-gear-65"></i> Default Dashboard : {{ model.project.project_code }} / {{ model.group.group_code }} / {{ model.site.site_code }} </b> -->

      </b-form-group>
      <button type="button" class="close" data-action="search-close" data-target="#navbar-search-main" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </b-form>
    <!-- Navbar links -->
    <b-navbar-nav class=" align-items-center ml-md-auto">
      <!-- This item dont have <b-nav-item> because they add class 'nav-link' which is not needed here -->
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div class="pr-3 sidenav-toggler"
             :class="{active: $sidebar.showSidebar, 'sidenav-toggler-dark': type === 'default', 'sidenav-toggler-light': type === 'light'}"
             @click="toggleSidebar">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
      <!-- This item dont have <b-nav-item> because item have data-action/data-target on tag <a>, wich we cant add -->
      <li class="nav-item d-sm-none" >
        <a class="nav-link" href="#" data-action="search-show" data-target="#navbar-search-main">
          <i class="ni ni-zoom-split-in"></i>
        </a>
      </li>
      <!-- <base-dropdown class="nav-item"
                     tag="li"
                     title-classes="nav-link"
                     title-tag="a"
                     icon="ni ni-bell-55"
                     menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden">
        <template>

          <div class="px-3 py-3">
            <h6 class="text-sm text-muted m-0">You have <strong class="text-primary">13</strong> notifications.</h6>
          </div>

          <b-list-group flush>
            <b-list-group-item action href="#!">
              <b-row align-v="center" >
                <b-col md="auto">

                  <b-img alt="Image placeholder" src="/img/theme/team-1.jpg" class="avatar" rounded="circle" />
                </b-col>
                <b-col class="ml--2">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 class="mb-0 text-sm">John Snow</h4>
                    </div>
                    <div class="text-right text-muted">
                      <small>2 hrs ago</small>
                    </div>
                  </div>
                  <p class="text-sm mb-0">Let's meet at Starbucks at 11:30. Wdyt?</p>
                </b-col>
              </b-row>
            </b-list-group-item>
            <a href="#!" class="list-group-item list-group-item-action">
              <b-row align-v="center">
                <b-col md="auto">

                  <b-img alt="Image placeholder" src="/img/theme/team-2.jpg" class="avatar" rounded="circle" />
                </b-col>
                <b-col class="ml--2">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 class="mb-0 text-sm">John Snow</h4>
                    </div>
                    <div class="text-right text-muted">
                      <small>3 hrs ago</small>
                    </div>
                  </div>
                  <p class="text-sm mb-0">A new issue has been reported for Argon.</p>
                </b-col>
              </b-row>
            </a>
            <b-list-group-item action href="#!" >
              <b-row align-v="center">
                <b-col md="auto">

                  <b-img alt="Image placeholder" src="/img/theme/team-3.jpg" class="avatar" rounded="circle" />
                </b-col>
                <b-col class="ml--2">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 class="mb-0 text-sm">John Snow</h4>
                    </div>
                    <div class="text-right text-muted">
                      <small>5 hrs ago</small>
                    </div>
                  </div>
                  <p class="text-sm mb-0">Your posts have been liked a lot.</p>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item action href="#!" >
              <b-row align-v="center">
                <b-col md="auto">

                  <b-img alt="Image placeholder" src="/img/theme/team-4.jpg" class="avatar" rounded="circle" />
                </b-col>
                <b-col class="ml--2">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 class="mb-0 text-sm">John Snow</h4>
                    </div>
                    <div class="text-right text-muted">
                      <small>2 hrs ago</small>
                    </div>
                  </div>
                  <p class="text-sm mb-0">Let's meet at Starbucks at 11:30. Wdyt?</p>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item action href="#!" >
              <b-row align-v="center">
                <b-col md="auto">

                  <b-img alt="Image placeholder" src="/img/theme/team-5.jpg" class="avatar" rounded="circle" />
                </b-col>
                <b-col class="ml--2">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 class="mb-0 text-sm">John Snow</h4>
                    </div>
                    <div class="text-right text-muted">
                      <small>3 hrs ago</small>
                    </div>
                  </div>
                  <p class="text-sm mb-0">A new issue has been reported for Argon.</p>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
          <a href="#!"  class="dropdown-item text-center text-primary font-weight-bold py-3">View all</a>
        </template>
      </base-dropdown> -->
      <!-- <base-dropdown menu-classes="dropdown-menu-lg dropdown-menu-dark bg-default dropdown-menu-right"
                     class="nav-item"
                     tag="li"
                     title-tag="a"
                     title-classes="nav-link"
                     icon="ni ni-ungroup">
        <template>
          <b-row class="shortcuts px-4">
            <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-red">
                      <i class="ni ni-calendar-grid-58"></i>
                    </span>
              <small>Calendar - W.I.P</small>
            </a>
            <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-orange">
                      <i class="ni ni-email-83"></i>
                    </span>
              <small>Email - W.I.P</small>
            </a>
            <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-info">
                      <i class="ni ni-credit-card"></i>
                    </span>
              <small>Payments - W.I.P</small>
            </a>
            <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-green">
                      <i class="ni ni-books"></i>
                    </span>
              <small>Reports - W.I.P</small>
            </a>
            <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-purple">
                      <i class="ni ni-pin-3"></i>
                    </span>
              <small>Maps - W.I.P</small>
            </a>
            <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-yellow">
                      <i class="ni ni-basket"></i>
                    </span>
              <small>Shop - W.I.P</small>
            </a>
          </b-row>
        </template>
      </base-dropdown> -->
    </b-navbar-nav>
    <b-navbar-nav class="align-items-center ml-auto ml-md-0" >
      <div class="mb-0 text-sm  font-weight-bold">iTrack</div>
      <base-dropdown menu-on-right
                     class="nav-item"
                     tag="li"
                     title-tag="a"
                     title-classes="nav-link pr-0" >
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <b-media no-body class="align-items-center">
                  <span class="avatar avatar-sm">
                    <!-- <img alt="Image placeholder" src="/img/theme/team-4.jpg"> --><b>{{ loginUserData.email.charAt(0).toUpperCase() }}</b>
                  </span>
            <b-media-body class="ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold">{{ loginUserData.email }}</span>
            </b-media-body>
          </b-media>
        </a>

        <template >

          <b-dropdown-header class="noti-title" >
            <h6 class="text-overflow m-0 text-white">Welcome!</h6>
          </b-dropdown-header>
          <b-dropdown-item @click="goPage('/pages/profile')" style="background-color: #033639;">
            <i class="ni ni-single-02 text-white"></i>
            <span class="text-white">My profile</span>
          </b-dropdown-item>
          <!-- <b-dropdown-item href="#!" style="background-color: #033639;">
            <i class="ni ni-settings-gear-65 text-white"></i>
            <span class="text-white">Settings - W.I.P</span>
          </b-dropdown-item>
          <b-dropdown-item href="#!" style="background-color: #033639;">
            <i class="ni ni-calendar-grid-58 text-white"></i>
            <span class="text-white">Activity - W.I.P</span>
          </b-dropdown-item>
          <b-dropdown-item href="#!" style="background-color: #033639;">
            <i class="ni ni-support-16 text-white"></i>
            <span class="text-white">Support - W.I.P</span>
          </b-dropdown-item> -->

          <b-dropdown-item @click="logoutFunction()" style="background-color: #033639;">
            <i class="ni ni-user-run text-white"></i>
            <span class="text-white">Logout</span>
          </b-dropdown-item>

        </template>
      </base-dropdown>
    </b-navbar-nav>
  </base-nav>

        <modal :show.sync="modals.formDashboard"
                   size="sm"
                   body-classes="p-0">
              <card type="secondary"
                    header-classes="bg-transparent pb-5"
                    body-classes="px-lg-5 py-lg-5"
                    class="border-0 mb-0">
                <template>
                  <div class="text-center text-muted mb-4">
                    <h3>Please Select Your Default Dashboard Project, Group, Site.</h3>
                  </div>
                  <b-form role="form">
                    <base-input label="Default Project" name="Default Project" v-model="model.project.project_id">
                      <select class="form-control" v-model="model.project.project_id">
                        <option value="x" disabled>-- Please Select A Default Project --</option>
                        <option v-for="item in projects" :key="item.project_id" v-bind:value="item.project_id">{{ item.project_code }}</option>
                      </select>
                    </base-input>
                    <base-input label="Default Group" name="Default Group" v-model="model.group.group_id">
                      <select class="form-control" v-model="model.group.group_id">
                        <option value="x" disabled>-- Please Select A Default Group --</option>
                        <option v-for="item in groups" :key="item.group_id" v-bind:value="item.group_id">{{ item.group_code }}</option>
                      </select>
                    </base-input>
                    <base-input label="Default Site" name="Default Site" v-model="model.site.site_id">
                      <select class="form-control" v-model="model.site.site_id">
                        <option value="x" disabled>-- Please Select A Default Site --</option>
                        <option v-for="item in sites" :key="item.site_id" v-bind:value="item.site_id">{{ item.site_code }}</option>
                      </select>
                    </base-input>
                    <div class="text-center">
                      <base-button type="primary" class="my-4" @click="notifyVueformDashboard()">Set A Default</base-button>
                      <base-button type="primary" class="my-4" @click="clearModal()">Cancel</base-button>
                    </div>
                  </b-form>
                </template>
              </card>
        </modal>
</div>
</template>
<script src="{{ asset('js/app.js?version='.date("ymdhis").'') }}"></script>
<script>
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from '@/components';
import axios from 'axios'
import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'

export default {
  components: {
    CollapseTransition,
    BaseNav,
    Modal
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
    }
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  // created()
  // {
  //     this.defaultDashboard = JSON.parse(window.localStorage.getItem('defaultDashboard'))
  //     this.model.project.project_id = this.defaultDashboard.project_id
  //     this.model.group.group_id = this.defaultDashboard.group_id
  //     this.model.site.site_id = this.defaultDashboard.site_id

  //     axios.get(apiDomain + 'project/project', { headers: getHeader() })
  //     .then ( response => {
  //     if (response.status === 200)
  //     {
  //         // this.model.project.project_id = response.data.data[0].project_id
  //         // this.model.project.project_code = response.data.data[0].project_code
  //         this.projects = response.data.data

  //         // this.project.project_code = this.projects.specs.filter(function(elem){
  //         //     if(elem.Name == this.model.project.project_id) return elem.project_code;
  //         // });

  //     }
  //     })
  //     .catch ( error => {
  //         console.log(error.response.status)
  //     });

  //     axios.get(apiDomain + 'project/project_group', { headers: getHeader() })
  //     .then ( response => {
  //     if (response.status === 200)
  //     {
  //         // this.model.group.group_id = response.data.data[0].group_id
  //         // this.model.group.group_code = response.data.data[0].group_code
  //         this.groups = response.data.data
  //     }
  //     })
  //     .catch ( error => {
  //         console.log(error.response.status)
  //     });

  //     axios.get(apiDomain + 'project/project_site', { headers: getHeader() })
  //     .then ( response => {
  //     if (response.status === 200)
  //     {
  //         // this.model.site.site_id = response.data.data[0].site_id
  //         // this.model.site.site_code = response.data.data[0].site_code
  //         this.sites = response.data.data
  //     }
  //     })
  //     .catch ( error => {
  //         console.log(error.response.status)
  //     });
  // },
  data() {
    return {
      model:
      {
        project:
        {
          project_id: null,
          project_code: null,
        },
        group:
        {
          group_id: null,
          group_code: null,
        },
        site:
        {
          site_id: null,
          site_code: null,
        },
      },
      defaultDashboard: [],
      projects: [],
      groups: [],
      sites: [],
      modals: {
        formDashboard: false,
      },
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      loginUserData : JSON.parse(window.localStorage.getItem('loginUser'))
    };
  },
  methods: {
    notifyVueformDashboard()
    {
      if ( this.model.project.project_id == 'x' || this.model.project.project_id == ''
      || this.model.group.group_id == 'x' || this.model.group.group_id == ''
      || this.model.site.site_id == 'x' || this.model.site.site_id == '')
      {
        this.$notify({
        message:
            '<b>Invalid Input : </b> - Your must select at least ONE Project & ONE Group & ONE Site.',
        timeout: 10000,
        icon: 'ni ni-bulb-61',
        type: 'danger',
        });
      }
      else
      {
        this.$notify({
        message:
          '<b>Default Dashboard : </b> - New Default Dashbord has been set and Save to your User Profile.',
        timeout: 10000,
        icon: 'ni ni-bulb-61',
        type: 'default',
        });
      }
      this.modals.formDashboard = false
    },
    clearModal()
    {
        this.modals.formDashboard = false
    },
    notready() {
          this.$notify({
          message:
            '<b>Under Developer : </b> - New Task, Site & Populate / Import From Template function is not ready.',
          timeout: 10000,
          icon: 'ni ni-bulb-61',
          type: 'danger',
          });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logoutFunction()
    {
      var loginUserData = JSON.parse(window.localStorage.getItem('loginUser'))
      console.log(loginUserData)
      if (loginUserData)
      {
        localStorage.clear()
      }
      this.$router.push({name: 'Login'})
    },
    goPage(page)
    {
        this.$router.push({path: page })
    },
  }
};
</script>
